import { KcContext } from './app/kcContext'

export const setupEnvironmentVariables = (context: KcContext) => {
  if (!(globalThis as any)._env_) {
    ;(globalThis as any)._env_ = {}
  }

  // https://docs.keycloakify.dev/v/v9/environment-variables
  // @ts-ignore It should be in properties according to the docs
  Object.entries(context.properties || {}).forEach(([key, value]) => {
    ;(globalThis as any)._env_[key] = value
  })
}

export const isValidEmail = (email: string) => /.+@.+/.test(email)

export const authPath = '/api/fire-auth/v1'

export const loginPath = `${authPath}/login`
